.dropdownLabel{
    color: var(--color-blue);
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: .3s all ease;
}

@media (max-width: 768px) {
    .dropdownLabel{
        font-size: 18px;
    }
}