.container {
    padding: 50px 15px 50px;
}

.list {
    color: var(--color-blue-text);
}

.list a {
    color: #3399CC;
    text-decoration: underline;
}

.list a:hover {
    text-decoration: none;
}

.listItem {
    display: flex;
    border-bottom: 1px solid #EBECEC;
    padding-bottom: 40px;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.listItem:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.listItemQuestion {
    flex: 0 0 320px;
    min-height: 48px;
    position: relative;
    font-weight: 700;
    font-size: 18px;
    margin-right: 40px;
    padding-left: 68px;
}

.listItemAnswer {
    flex: 1 1 320px;
    position: relative;
    padding-left: 68px;
    font-size: 18px;
    line-height: 30px;
}

.listItemQuestion::before, .listItemAnswer::before  {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.listItemQuestion::before {
    background-image: url('/icons/x.svg');
    background-color: rgba(255, 59, 48, 0.2);
}

.listItemAnswer::before {
    background-image: url('/icons/check.svg');
    background-color: rgba(52, 199, 89, 0.2);
}

@media (max-width: 768px) {
    .listItem {
        display: block;
    }
    .listItemAnswer {
        margin-top: 30px;
    }
}