.sliderIndicators{
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 10px;

}

.circle{
    width: 10px;
    height: 10px;
    margin: 4px;
    border-radius: 100%;
    background: var(--color-grey-slider-indicator);
    transition: .3s all ease;
}
.circle.active{
    background: var(--color-orange-lighter);
}

@media (min-width: 769px){
    .sliderIndicators{
        display: none;
    }
}