.container {
    padding: 40px 15px 60px;
    background-color: var(--color-light-grey-bg);
}

.list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 70px;
}

.listItem {
    flex: 20%;
    text-align: center;
    margin-bottom: 8px;
    padding: 0 25px;
    box-sizing: border-box;
    min-width: 200px;
}

.listItemNum {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    margin-bottom: 17px;
    color: #fff;
    font-weight: 500;
    background-color: var(--color-orange-lighter);
    border-radius: 50%;
    font-size: 24px;
}

.listItemName {
    color: var(--color-blue-text);
    font-size: 14px;
    font-weight: 700;
}

@media (max-width: 768px) {
    .illustration {
        display: none;
    }
}

@media (max-width: 568px) {
    .list {
        margin-top: 40px;
        display: block;
    }
    .listItem {
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #EBECEC;
        box-sizing: border-box;
        border-radius: 14px;
        padding: 20px;
        text-align: left;
    }
    .listItemNum {
        width: 52px;
        height: 52px;
        min-width: 52px;
        margin: 0 20px 0 0;
    }
}