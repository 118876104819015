.container {
    padding: 50px 15px 70px;
    background-color: var(--color-light-grey-bg);
}

.list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.listItem {
    padding: 0 15px;
    flex: 0 0 220px
}

.listItem img {
    max-width: 100%;
}

.listItem img {
    filter: grayscale(1);
    transition: filter .2s ease-in-out;
}

.listItem img:hover {
    filter: grayscale(0)
}