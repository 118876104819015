.container{
    padding-top: 60px;
    padding-bottom: 120px;
}

@media (max-width: 768px) {
    .container{
        padding-top: 35px;
        padding-bottom: 60px;
    }
    .container > * > h2, .container > * >  p{
        margin-left: 30px;
        margin-right: 30px;
    }
}