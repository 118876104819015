.content{
    margin-top: var(--nav-offset-px);
    padding: 0 20px;
    box-sizing: border-box;
}
.title{
    text-align: center;
}
.statusCode, .statusText{
    text-align: center;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.statusCode{
    color: var(--color-blue-text);
    font-size: 96px;
    font-weight: bold;
    margin-right: 20px;
    margin-bottom: 0;
}

.statusText{
    color: var(--color-orange-lighter);
    font-size: 46px;
    font-weight: 300;
}

.buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}

@media (max-width: 768px){
    .content{
        padding: 0 30px;
    }
    .statusCode{
        display: block;
    }
    .buttons{
        display: block;
        margin-top: 30px;
        margin-bottom: 45px;
    }
    .buttons button{
        margin: 15px 0;
    }
}