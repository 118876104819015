.closeButton{
    width: 40px;
    height: 40px;
    display: block;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 10px;
}

.closeButton svg path{
    fill: #ccc;
    transition: .3s all ease;
}

.closeButton:hover svg path{
    fill: var(--color-orange-saturated);
}

.closeButton:focus{
    outline: none;
}