.modalWrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1000;
    transition: opacity .3s ease-in-out;
}

.closeButtonContainer {
    position: absolute;
    top: -35px;
    right: -35px;
}

@media (max-width: 568px) {
    .closeButtonContainer {
        top: -40px;
        right: -10px;
    }
}

.modalBody {
    position: relative;
    min-width: 320px;
    max-width: 700px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 8px 30px 0 var(--color-shadow-blue);
    transition: .3s all ease;
    border: 1px solid var(--color-light-grey-border);
}

.modalContent {

}

.shown {
    opacity: 1;
    visibility: visible;
}

.hidden {
    opacity: 0;
    visibility: hidden;
}

@media (max-width: 768px) {
    .modalBody {
        width: 500px;
    }
}

@media (max-width: 568px) {
    .modalBody {
        width: 100%;
    }
}