.container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 50px 40px;
    box-sizing: border-box;
    width: 100%;
    background: white;
}

.imageContainer{
    width: 50%;
    display: flex;
    justify-content: center;
}



.textContainer{
    width: 50%;
}

@media (max-width: 768px) {
    .container{
        padding: 60px 30px 50px 30px;
        display: block;
    }

    .imageContainer{
        width: 100%;
        display: block;
    }
    .imageContainer svg{
        width: 100%;
        height: auto;
    }
    .textContainer{
        width: 100%;
    }
}