.contactFormWrapper{
}
.formTitle{
    color: var(--color-blue);
    margin: 0;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 18px;
}

.contactFormWrapper button{
    margin-left: 0;
    margin-top: 30px;
    margin-bottom: 0px;
}

.formLabel{
    padding-top: 25px;
    padding-bottom: 20px;
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #151515;
}
.successScreen.hidden, .failScreen.hidden, .loadingScreen.hidden, .formContent.hidden{
    opacity: 0;
    pointer-events: none;
    position: absolute;
}

.loadingScreen{
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadingTitle{
    margin-top: 20px;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    color: var(--color-blue-text);
}

.formSubmittedTitle{
    color: var(--color-blue);
    margin: 0;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 18px;
}

.formSubmittedText{
    color: var(--color-blue-text);
    margin-bottom: 10px;
    font-size: 15px;
}

.successScreen button, .failScreen button{
    margin-top: 10px;
    width: 100%;
}

@media (max-width: 768px) {
    .contactFormWrapper button{
        margin-right: 0;
        width: 100%;
    }
}