.container{
    padding-top: 50px;
    padding-bottom: 50px;
}

.controlsContainer{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.phoneLink{
    padding: 20px 0;
    padding-right: 68px;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: var(--color-blue-text);
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: .3s all ease;
}

.phoneLink:hover{
    color: var(--color-orange-saturated);
}

.phoneLink svg{
    margin-right: 12px;
}

.phoneLink svg path{
    fill: var(--color-orange-lighter);
}

@media(max-width: 768px){
    .container{
        padding: 30px;
    }
    .controlsContainer{
        display: block;
    }
    .controlsContainer button{
        margin-left: 0;
    }
}