.footerColumns{
    display: flex;
}

.column{
    padding: 0 60px;
}

.colHeader{
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 30px;
    color: var(--color-blue-text);
    font-weight: bold;
}

.colValue{
    display: block;
    text-decoration: none;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-blue-text);
    font-weight: 400;
    transition: .3s all ease;
}

.colValue:hover{
    color: var(--color-orange-saturated);
}

@media (max-width: 768px) {
    .footerColumns{
        display: block;
    }
    .column{
        padding: 0;
        margin-bottom: 40px;
    }
    .colHeader{
        line-height: 14px;
        margin-bottom: 15px;
    }
}