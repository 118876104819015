@font-face {
  font-family: 'Open Sans';
src: url('./static/fonts/opensanslight.woff2') format('woff2'),
  url('./static/fonts/opensanslight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
src: url('./static/fonts/opensans.woff2') format('woff2'),
  url('./static/fonts/opensans.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
src: url('./static/fonts/opensanssemibold.woff2') format('woff2'),
  url('./static/fonts/opensanssemibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url('./static/fonts/Inter-Thin.woff2') format("woff2"),
  url('./static/fonts/Inter-Thin.woff') format("woff");

}

@font-face {
  font-family: 'Inter';
font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url('./static/fonts/Inter-Light.woff2') format("woff2"),
  url('./static/fonts/Inter-Light.woff') format("woff");
}

@font-face {
  font-family: 'Inter';
font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url('./static/fonts/Inter-Regular.woff2') format("woff2"),
  url('./static/fonts/Inter-Regular.woff') format("woff");
}

@font-face {
  font-family: 'Inter';
font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url('./static/fonts/Inter-Bold.woff2') format("woff2"),
  url('./static/fonts/Inter-Bold.woff') format("woff");
}

@font-face {
  font-family: 'Inter';
font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url('./static/fonts/Inter-Medium.woff2') format("woff2"),
  url('./static/fonts/Inter-Medium.woff') format("woff");
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-blue);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-blue: #142c4c;
  --color-blue-text: #102a4a;
  --color-blue-mid: #0e2c50;

  --color-orange-lighter: #ff981e;
  --color-orange-mid: #ff9313;
  --color-orange-saturated: #ff8a00;

  --color-shadow-blue: rgba(157, 179, 194, 0.24);

  --color-light-grey-bg: #f9f9f9;
  --color-light-grey-border: #ececec;
  --color-grey-input-border: #ccc;
  --color-grey-slider-indicator: #e4e4e4;
  --color-input-error-border: #ff8282;

  --intro-animation-curve-ifs: ease;

  --nav-offset-px: 199px;
}

@media (max-width: 1440px) {
  :root {
    --nav-offset-px: 147px;
  }
}


@media (max-width: 1240px) {
  :root {
    --nav-offset-px: 117px;
  }
}

@media (max-width: 768px) {
  :root {
    --nav-offset-px: 60px;
  }
}

::selection{
  color: white;
  background: var(--color-orange-lighter);
}

*, *::before {
  box-sizing: border-box;
}

ol {
  text-align: left;
  line-height: 27px;
}

ul {
  padding-left: 20px;
  line-height: 27px;
}

#application-root {
  overflow-x: hidden;
}

.wrap {
  overflow-x: hidden;
}

.css-g1d714-ValueContainer {
  padding: 10px !important;
}
