.fullReportingCard{
    padding: 50px 40px;
    box-sizing: border-box;
    width: 100%;
    height: 340px;
    color: var(--color-blue-text);
    border-radius: 14px;
    border: 1px solid var(--color-light-grey-border);
    box-shadow: 0 8px 30px 0 var(--color-shadow-blue);
}

.fullReportingCard svg{
    fill: var(--color-orange-lighter);
    height: 68px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.fullReportingCard.white{
    background: white;
    color: var(--color-blue-text)!important;
}

.fullReportingCard.white svg{
    fill: var(--color-orange-mid);
}

.fullReportingCard.orange{
    background: var(--color-orange-mid);
    color: white!important;
}

.fullReportingCard.orange svg{
    fill: white;
}

.fullReportingCard.blue{
    background: var(--color-blue-mid);
    color: white!important;
}

.fullReportingCard.blue svg{
    fill: var(--color-orange-mid);
}

.fullReportingCard h3{
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
}

.fullReportingCard p{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

@media (max-width: 768px) {
    .fullReportingCard{
        height: auto;
        width: calc(100vw - 30px);
        flex-shrink: 0;
        margin:  20px 15px 30px 15px;
        scroll-snap-align: center;
    }
    .fullReportingCard p{
        margin-bottom: 0;
    }
}