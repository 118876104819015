.tariffs {
  display: flex;
  margin-left: -12px;
  margin-right: -12px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .tariffs {
      display: block;
  }
}

.tariffsItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 12px;
  flex: 0 0 354px;
  border: 1px solid #EBECEC;
  box-sizing: border-box;
  box-shadow: 0px 8px 30px rgba(157, 179, 194, 0.24);
  border-radius: 14px;
  padding: 30px;
  margin-bottom: 20px;
}

.tariffsItemBody {
  margin-bottom: 20px;
}

.tariffsItemAdditional {
  box-shadow: none;
}

.tariffsItemName {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.tariffsItemPrice {
  position: relative;
}

.tariffsItemPrice strong {
  display: inline-block;
  font-size: 28px;
  font-weight: 700;
  margin-right: 10px;
}

.tariffsItemPriceCurrency {
  display: inline-block;
  font-size: 14px;
  color: rgba(16, 42, 74, 0.6);
  vertical-align: top;
}

.tariffsItemList {
  margin-top: 20px;
}

.tariffsItemListInfo {
  display: block;
  margin-bottom: 20px;
}

.tariffsItemListItem {
  position: relative;
  padding-left: 36px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tariffsItemListItem::before {
  content: '';
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  background: url('/icons/check-circle.svg') no-repeat;
}

.tariffsItemListHr {
  border: 1px solid #EBECEC;
}

.tariffsItemButton {
  margin: 0;
}
