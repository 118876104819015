.logo{

    transition: .3s all ease;

    stroke: var(--color-orange-saturated);
    fill: transparent;
    stroke-linejoin: round;
    stroke-linecap: round;
    animation: strokeAnimation 2s infinite linear both;
    stroke-dasharray: 3px;
    stroke-dashoffset: 0;
}

@keyframes strokeAnimation {
    0%{
        stroke-dashoffset: 0%;
    }
    50%{
        stroke-dashoffset: 100%;
        stroke: var(--color-blue);

    }
    100%{
        stroke-dashoffset: 200%;
    }
}