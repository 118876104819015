.introductionBlockTopContent{
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--nav-offset-px);
  margin-bottom: 110px;
  margin-left: -20px;
  margin-right: -20px;
  box-sizing: border-box;
}
.introductionBlockBottomContent{
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

@media(max-width: 768px){
  .introductionBlockTopContent{
      display: block;
      padding: 0 30px;
      margin-bottom: 0;
  }

  .introductionBlockBottomContent{
      display: none;
  }
}