.cardsContainer{
    margin-top: 54px;
}

@media (max-width: 768px) {
    .cardsContainer{
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        width: 100%;
        margin-top: 0;
    }
    .cardsContainer::-webkit-scrollbar {
        display: none;
    }
    .mock{
        width: 1px;
        flex-shrink: 0;
        margin-left: -1px;
    }
}