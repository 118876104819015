.dialogWrapper{
    position: fixed;
    z-index: 2;
    bottom: 20px;
    right: 20px;
    width: 390px;
    min-height: 455px;
    padding: 60px 40px;
    box-sizing: border-box;
    background: white;
    border-radius: 14px;
    box-shadow: 0 8px 30px 0 var(--color-shadow-blue);
    transition: .3s all ease;
    border: 1px solid var(--color-light-grey-border);
    z-index: 2000;
}
.dialogWrapper.shown{

}
.dialogWrapper.hidden{
    opacity: 0;
    pointer-events: none;
    transform: translateX(500px);
}

.closeButtonContainer{
    top: 10px;
    right: 10px;
    position: absolute;
}

@media (max-width: 768px) {
    .dialogWrapper{
        top: var(--nav-offset-px);
        left: 15px;
        right: 15px;
        bottom: 15px;
        width: auto;
    }
}