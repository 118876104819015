.navigationList{
    display: flex;
}

@media (orientation: landscape) and (max-width: 768px){
    .navigationList{
        padding-top: 0;
        overflow-y: auto;
    }
}

    @media (max-width: 768px) {
    .navigationList{
        padding-top: 40px;
        position: fixed;
        top: var(--nav-offset-px);
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        display: block;
        transition: .2s all ease;
    }
    .navigationList.menuCollapsed{
        opacity: 0;
        pointer-events: none;
        transform: scale(.4) translateY(350px);
    }
    .navigationList.menuExpanded{
        opacity: 1;
    }
}