.navigationListItem{
    color: var(--color-blue);
    font-weight: 500;
    font-size: 16px;
    cursor: pointer!important;
    transition: .3s color ease;
    margin: 0 5px;
    padding: 35px 35px;
}

.navigationListItem:hover{
    color: var(--color-orange-saturated);
    cursor: pointer!important;
}

@media (max-width: 1240px) {
    .navigationListItem{
        padding: 20px;
    }
}

@media (max-width: 768px) {
    .navigationListItem{
        font-size: 18px;
        padding: 25px 35px;
    }
}