*{
    text-decoration: none;
}
.bgImageContainer{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-orange-mid);
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center!important;
    transition: all .5s ease!important;
}
.caseCard{
    overflow: hidden;
    position: relative;
    padding: 40px;
    box-sizing: border-box;
    width: 100%;
    min-width: 350px;
    height: 280px;
    border-radius: 14px;
    transform: translateZ(0); /*Fix for the border radius issue on hover*/
    cursor: pointer;
}

.caseCard:hover .bgImageContainer{
    transform: scale(1.1);
    opacity: 1;
}

.caseCard p{
    position: relative;
    z-index: 2;
    margin-top: 35px;
    color: white;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration: none;
    margin: 5px 0 0;
}

.caseCard svg{
    display: block;
    position: relative;
    z-index: 2;
    transition: .3s all ease;
    margin-bottom: 20px;
}

.caseCard.caseCardEmpty:hover svg{
    transform: rotate(90deg);
}

.caseCard svg path{
    fill: white;
}

@media(max-width: 768px){
    .caseCard{
        min-width: calc(100vw - 30px);
        margin: 0 15px 30px 15px;
        scroll-snap-align: center;
    }
    .caseCard .bgImageContainer{
        opacity: 1;
    }
}