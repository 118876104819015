.solutionCapabilitiesCard{
    padding: 60px 40px;
    box-sizing: border-box;
    width: 100%;
    color: var(--color-blue-text);
    border-radius: 14px;
    border: 1px solid var(--color-light-grey-border);
    box-shadow: 0 8px 30px 0 var(--color-shadow-blue);
    background: white;
}

.solutionCapabilitiesCard img {
    height: 58px;
}

.solutionCapabilitiesCard h3{
    margin-bottom: 0;
}

.solutionCapabilitiesCard p{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    margin-top: 15px;
}


@media (max-width: 768px) {
    .solutionCapabilitiesCard{
        margin: 20px 15px 30px 15px;
        scroll-snap-align: center;
        width: calc(100vw - 30px);
        flex-shrink: 0;
    }
}