.scrollArrowImage{
    cursor: pointer;
    animation: bouncing 2.5s infinite cubic-bezier(0,.78,.56,1);
}
.container{
    fill: none;
    stroke: #ededed;
    stroke-width: 1px;
}
.arrow{
    fill: #ff9313;
    fill-rule: evenodd;
}

@keyframes bouncing{
    0%{
        transform: initial;
        opacity:0;
    }
    48%{
        opacity:1;
        transform: translateY(36);
    }
    100%{
        opacity:0;
        transform: translateY(48px);

    }
}