.container {
    padding: 50px 15px 50px;
    color: var(--color-blue-text)
}

.category {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.select {
    display: none;
    position: relative;
    z-index: 500;
    margin-bottom: 25px;
}

.categoryItem {
    position: relative;
    margin-bottom: 40px;
}

.categoryItemIcon {
    text-align: center;
    margin-bottom: 20px;
}

.categoryList {
    position: relative;
}

.categoryListItem {
    display: block;
    width: 175px;
    padding: 15px;
    background-color: #fff;
    outline: none;
    border: 1px solid;
    border-color: #EDEDED;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 4px;
    font-size: 16px;
    transition: all .2s ease-in-out;
}

.categoryListItem:hover, .categoryListItemActive {
    color: var(--color-orange-lighter);
    background-color: rgba(255, 152, 30, 0.08);
    border-color: var(--color-orange-lighter);
}

@media (max-width: 568px) {
    .category {display: none;}
    .select {display: block;}
}