.introductionForm{
    width: 588px;
    padding: 0 20px;
}
.blockButtons{
    padding-top: 6px;
}
.blockButtons > a:first-child, .blockButtons > a:first-child > button{
    margin-left: 0;
}

@media (max-width: 768px) {
    .introductionForm{
        width: 100%;
        margin-left: 0;
    }
    .blockMobile{
        display: block;
    }
}