.container {
    padding: 50px 15px 50px;
}

.timeLine {
    padding-top: 50px;
}

.timeLineStep {
    display: flex;
}

.timeLineStep:last-child .timeLineStepItem {
    border: 0 !important;
}

.timeLineStepItem {
    display: flex;
    width: 100%;
}

.timeLineStepItemNum {
    flex: 0 0 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    font-size: 24px;
    background-color: #fff;
    border: 1px solid #EBECEC;
    border-radius: 50%;
    box-shadow: 0px 8px 30px rgba(157, 179, 194, 0.24);
}

.timeLineStepItemDesc {
    font-size: 19px;
    line-height: 30px;
}

@media (max-width: 776px) {
    .timeLine {
        padding-left: 30px;
    }
    .timeLineStepItem {
        padding-bottom: 30px;
        border-left: 2px dashed #EBECEC;
    }
    .timeLineStepItemNum {
        transform: translateX(-50%);
    }
}

@media (min-width: 776px) {
    .timeLineStepItem {
        min-height: 154px;
    }
    .timeLineStep:nth-child(even) {
        margin-right: 50%;
        transform: translateX(1px);
    }
    
    .timeLineStep:nth-child(odd) {
        margin-left: 50%;
        transform: translateX(-1px);
    }
    .timeLineStep:nth-child(even) .timeLineStepItemDesc {
        text-align: right;
        margin-right: -10px;
    }
    
    .timeLineStep:nth-child(odd) .timeLineStepItemDesc {
        text-align: left;
        margin-left: -10px;
    }
    
    .timeLineStep:nth-child(even) .timeLineStepItem {
        flex-direction: row-reverse;
        border-right: 2px solid #EBECEC;
        margin-right: -1px
    }
    
    .timeLineStep:nth-child(odd) .timeLineStepItem {
        border-left: 2px solid #EBECEC;
        margin-left: -1px
    }
    
    .timeLineStep:nth-child(even) .timeLineStepItemNum {
        transform: translateX(50%);
    }
    
    .timeLineStep:nth-child(odd) .timeLineStepItemNum {
        transform: translateX(-50%);
    }
}