.inputWrapper {
    box-sizing: border-box;
    border: 1px solid var(--color-grey-input-border);
    border-radius: 3px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
}
.inputWrapper.focused, .inputWrapper.focused .countryCode {
    border-color: var(--color-orange-lighter);
}
.inputWrapper input{
    padding: 18px 0;
    padding-left: 15px;
    flex: 1;
    font-family: inherit;
    font-size: inherit;
    border: none;
    width: 60%;
}

.inputWrapper input:focus {
    outline: none;
}

.countryCode{
    display: inline-block;
    width: 25%;
    padding: 18px 0;
    text-align: center;
    border-right: 1px solid  var(--color-grey-input-border);
}

.inputWrapper.invalidValue, .inputWrapper.invalidValue .countryCode{
    border-color: var(--color-input-error-border);
}

.inputWrapper.invalidValue.focused, .inputWrapper.invalidValue.focused .countryCode{
    border-color: var(--color-orange-lighter);
}