.container {
    padding: 50px 15px 50px;
}

.categoryList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -6px;
    margin-right: -6px;
    margin-bottom: 45px;
}

.categoryListItem {
    display: block;
    width: 175px;
    padding: 15px;
    margin: 0 6px;
    background-color: #fff;
    outline: none;
    border: 1px solid;
    border-color: #EDEDED;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    margin-bottom: 4px;
    font-size: 16px;
    transition: all .2s ease-in-out;
}

.categoryListItem:hover {
    color: var(--color-orange-lighter);
    background-color: rgba(255, 152, 30, 0.08);
    border-color: var(--color-orange-lighter);
}