.cardsContainer{
    margin-top: 72px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -12px;
    margin-left: -12px;
}

@media (max-width: 768px) {
    .cardsContainer{
        display: block;
        margin-top: 35px;
    }
}