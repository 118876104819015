.systemConnectionCard{
    padding: 40px;
    box-sizing: border-box;
    width: 100%;
    height: 274px;
    color: var(--color-blue-text);
    border-radius: 14px;
    border: 1px solid var(--color-light-grey-border);
    box-shadow: 0 8px 30px 0 var(--color-shadow-blue);
    background: white;
}

.systemConnectionCard svg{
    fill: var(--color-orange-lighter);
    margin-bottom: 10px;
}

.systemConnectionCard h3{
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
}

.systemConnectionCard p{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

@media (max-width: 768px) {
    .systemConnectionCard{
        height: auto;
        margin: 20px 15px 30px 15px;
        width: calc(100vw - 30px);
        flex-shrink: 0;
    }
    .systemConnectionCard p{
        margin-bottom: 0;
        scroll-snap-align: center;
    }
}