.container {
    padding: 50px 15px 70px;
}

.content {
    display: flex;
    margin-left: -30px;
    margin-right: -30px;
}

.item {
    flex: 0 0 50%;
    padding: 0 30px;
}

.item img {
    display: block;
    max-width: 100%;
}

.infoSection + .infoSection {
    margin-top: 45px;
}

.infoSection strong {
    display: inline-block;
    font-size: 19px;
    line-height: 30px;
}

.infoSection p {
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 15px;
}

.infoSection p:last-child {
    margin: 0;
}

@media (max-width: 992px) {
    .content {
        flex-direction: column;
    }
    .customerPhoto {
        max-width: 200px;
        margin-bottom: 20px;
    }
    .container {
        padding: 20px 15px 70px;
    }
}