.container {
    padding: 50px 15px 50px;
    background-color: var(--color-light-grey-bg);
}

.list {
    display: flex;
}

.listItem {
    flex: 0 0 33%;
    text-align: center;
    padding: 0 30px 30px;
    margin-top: 50px;
}

.listItemNum {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    color: #fff;
    background-color: var(--color-orange-lighter);
    border-radius: 50%;
    font-size: 24px;
    margin-bottom: 35px;
}

.listItemContent {
    font-size: 16px;
    line-height: 26px;
}

@media (max-width: 776px) {
    .list {
        flex-direction: column;
    }
    .listItem + .listItem {
        margin-top: 30px;
    }
}