.container{
    padding-top: 95px;
    background: var(--color-light-grey-bg);
    padding-bottom: 120px;
}

@media(max-width: 768px){
    .container{
        padding-top: 35px;
        padding-bottom: 60px;
    }
    .container h2{
        padding-left: 30px;
        padding-right: 30px;
    }
}