.container {
    padding: 50px 15px 80px;
    background-color: var(--color-light-grey-bg);
}

.results {
    display: flex;
    color: var(--color-blue-text);
    margin-bottom: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #DBDFDF;
}

.resultsFooter {
    text-align: center;
}

.item {
    flex: 50%;
    text-align: center;
    padding: 0 15px;
}


@media (min-width: 768px) {
    .item:first-child {
        border-right: 1px solid #DBDFDF;
    }
}

@media (max-width: 768px) {
    .results {
        display: block;
        padding-bottom: 0;
        margin-bottom: 30px;
    }
    .item {
        padding-bottom: 10px;
        margin-bottom: 30px;
        padding: 0;
    }
    .item:first-child {
        border-bottom: 1px solid #DBDFDF;
    }
}

.itemTitle {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 40px;
}

.itemList {
    position: relative;
}

.itemListSection {
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.itemListSection::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 2px;
    background-color: var(--color-orange-lighter);
}

.itemListSection:last-child::before {
    content: none;
}

.itemListSection:last-child {
    margin-bottom: 0;
}

.itemListSection strong {
    display: block;
    font-size: 18px;
    line-height: 1.4;
}

.itemListSection span {
    display: block;
    margin-top: 10px;
    font-size: 16px;
}

.resultsFooterLink {
    display: inline-block;
    font-size: 11px;
    color: var(--color-orange-lighter);
    letter-spacing: 2.2px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 0;
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.secondaryText {
    margin-top: 30px;
}