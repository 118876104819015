.container{
    padding-top: 45px;
    padding-bottom: 45px;
}

@media (max-width: 768px) {
    .container{
        padding-top: 35px;
        padding-bottom: 30px;
    }
    .container h2{
        padding-left: 30px;
        padding-right: 30px;
    }
}