.capabilityCard{
    display: inline-block;
    padding: 50px 40px;
    box-sizing: border-box;
    width: calc(50% - 18px);
    height: 298px;
    margin: 15px 16px 25px 16px;
    color: var(--color-blue-text);
    border-radius: 14px;
    border: 1px solid var(--color-light-grey-border);
    box-shadow: 0 8px 30px 0 var(--color-shadow-blue);
}

.capabilityCard:nth-child(even){
    margin-right: 0;
}
.capabilityCard:nth-child(odd){
    margin-left: 0;
}

.capabilityCard svg{
    fill: var(--color-orange-lighter);
    height: 68px;
    margin-bottom: 10px;
}

.capabilityCard.accent{
    background: var(--color-orange-mid);
    color: white!important;
    border: 1px solid var(--color-orange-mid);
}

.capabilityCard.accent svg{
    fill: white;
}

.capabilityCard h3{
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
}

.capabilityCard p{
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

@media(max-width: 768px){
    .capabilityCard{
        flex-shrink: 0;
        width: calc(100vw - 30px);
        padding: 30px;
        height: auto;
        scroll-snap-align: center;
    }
    .capabilityCard:nth-child(even){
        margin-right: 16px;
    }
    .capabilityCard:nth-child(odd){
        margin-left: 16px;
    }
    .capabilityCard h3{
        margin-top: 0;
        margin-bottom: 10px;
    }
    .capabilityCard p{
        margin-top: 5px;
        margin-bottom: 0;
    }
}