.swiper-button-next, .swiper-button-prev {
  width: 30px;
  height: 30px;
  background: #ccc;
  backdrop-filter: blur(8px);
  border-radius: 50%;
  transform: translateY(-15px);
}

.swiper-button-next {
  right: -5px;
}

.swiper-button-prev {
  left: -5px;
}

@media (min-width: 568px) {
  .modal .swiper-button-next {
    right: -50px;
  }
  
  .modal .swiper-button-prev {
    left: -50px;
  }
}

@media (max-width: 568px) {
  .modal .swiper-button-next, .modal .swiper-button-prev {
    display: none;
  }
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 13px;
  color: #fff;
}

.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: 0;
}

.swiper-container {
  padding: 0 0 10px;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #E4E4E4;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #F29C40;
}

.swiper-container img {
  display: block;
  max-width: 100%;
}