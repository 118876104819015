.outerWrapper{
    margin-top: 70px;
}
.cardsContainer{
    display: grid;
    grid-template-columns: repeat(3, 350px);
    grid-auto-rows: 340px;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
}

.mock{
    display: none;
}

@media (max-width: 1180px) {
    .cardsContainer{
        grid-template-columns: repeat(2, 350px);
    }
    .outerWrapper{
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 768px) {
    .cardsContainer{
        width: 100%;
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
    }
    .cardsContainer::-webkit-scrollbar{
        display: none;
    }
    .outerWrapper{
        margin-top: 20px;
        display: block;
    }
    .mock{
        display: block;
        width: 1px;
        flex-shrink: 0;
        margin-left: -1px;
    }
}