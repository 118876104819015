.dropdownListItem{
    cursor: pointer;
    display: block;
    padding: 12px 30px;
    color: var(--color-blue-text);
    font-size: 16px;
    font-weight: 500;
    transition: .3s all ease;
    text-decoration: none;
}

.dropdownListItem:hover{
    color: var(--color-orange-saturated);
}