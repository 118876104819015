.container {
    padding: 50px 15px 80px;
}

.msg {
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}

.text {
    font-size: 19px;
    line-height: 30px;
}