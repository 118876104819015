.container {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}

.container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 11.46%, rgba(0, 0, 0, 0.2) 62.29%);
}

.content {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    min-height: 750px;
    padding-top: var(--nav-offset-px);
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    color: #fff;
}

.wrap {
    margin-left: -15px;
    margin-right: -15px;
    padding: 40px 0;
}

.column {
    padding: 0 15px;
}

.linkToBack {
    display: inline-flex;
    align-items: center;
    color: #fff;
    letter-spacing: 2.2px;
    text-transform: uppercase;
    font-weight: 700;
}

.arrowLeft {
    display: inline-block;
    margin-right: 10px;
}

.titleBox {
    max-width: 50%;
}

@media (max-width: 992px) {
    .titleBox {
        max-width: 100%;
    }
    .content {
        min-height: auto;
    }
}