.container {
    background-color: var(--color-light-grey-bg);
    padding: 50px 15px 70px;
}

.wrap {
    display: flex;
    margin-left: -30px;
    margin-right: -30px;
    padding-top: 20px;
}

.picture img {
    max-width: 100%;
}

.column {
    width: 50%;
    padding: 0 30px;
}

.listTitle {
    font-weight: 700;
    font-size: 19px;
    margin-bottom: 30px;
}

.listItem {
    position: relative;
    min-height: 40px;
    margin-bottom: 30px;
    line-height: 26px;
    padding-left: 65px;
}

.listItem:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    background: url('/icons/check.svg') no-repeat center;
    border-radius: 50%;
    border: 2px solid rgba(52, 199, 89, 0.2);
}

@media (max-width: 768px) {
    .wrap {
        flex-direction: column;
    }
    .column {
        width: 100%;
    }
    .list {
        margin-top: 30px;
    }
}