.container{
    padding-top: 30px;
    padding-bottom: 90px;
}

@media (max-width: 768px){
    .container > p, .container > h2{
        padding-left: 30px;
        padding-right: 30px;
    }
}