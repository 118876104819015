.dropdownList{
    position: relative;
}

.label{
    display: flex;
    align-items: center;
    padding: 35px;
    margin: 0 5px;
    cursor: pointer;
}

.label:hover  > *{
    color: var(--color-orange-saturated);
}

.label > *{
    padding: 0;
}

.dropdownList.collapsed .items{
    opacity: 0;
    pointer-events: none;
    transform: translateY(50px);
}

.items{
    position: absolute;
    top: 80px;
    left: 35px;
    box-sizing: border-box;
    width: 218px;
    padding: 24px 0;
    background: white;
    box-shadow: 0 8px 30px 0 var(--color-shadow-blue);
    border-radius: 8px;
    transition: .3s all ease;
}

.items:focus{
    outline: none;
}

.stateIndicator{
    margin-left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: var(--color-blue-text) transparent transparent transparent;
    transition: .3s all ease;
}

.dropdownList.shown .stateIndicator{
    transform: rotate(180deg);
}

.label:hover .stateIndicator{
    border-color: var(--color-orange-saturated) transparent transparent transparent;
}

@media (max-width: 1240px) {
    .label{
        padding: 20px;
    }
    .items{
        top: 65px;
        left: 20px;
    }
}

@media (max-width: 768px) {
    .label{
        padding: 25px 35px;
    }
    .items{
        padding: 14px 0;
    }
}