.container {
    padding: 50px 16px 30px;
    background-color: var(--color-light-grey-bg);
}

.list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-left: -16px;
    margin-top: 60px;
}

.listItemWrap {
    flex: 0 0 50%;
    padding: 0 16px;
}

.listItem {
    display: flex;
    padding: 0 16px;
    margin-bottom: 50px;
}

.listItemIcon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80px;
    min-width: 80px;
    background-color: #fff;
    border-radius: 50%;
    margin-bottom: 20px;
}

.listItemBody {
    color: var(--color-blue-text)
}

.listItemTitle {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
}

.listItemDesc {
    font-size: 14px;
    line-height: 24px;
}

@media (min-width: 768px) {
    .listItemIcon {
        height: 80px;
        margin-right: 32px;
        justify-content: center;
        border: 1px solid #EBECEC;
        box-shadow: 0px 8px 30px rgba(157, 179, 194, 0.24);
    }
}

@media (max-width: 768px) {
    .list {
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        flex-wrap: nowrap;
        margin-top: 40px;
    }
    .listItemWrap {
        flex: 0 0 100%;
        width: 100vw;
        scroll-snap-align: center;
        padding-bottom: 30px;
    }
    .listItem {
        display: block;
        background: #fff;
        border: 1px solid #EBECEC;
        box-sizing: border-box;
        box-shadow: 0px 8px 30px rgba(157, 179, 194, 0.24);
        border-radius: 14px;
        padding: 30px;
        margin-bottom: 0;
    }
}