.blockTitle{
    color: var(--color-blue-text);
    font-size: 42px;
    line-height: 54px;
    font-weight: bold;
    margin-bottom: 40px;
}

.blockTitle span {
    color: var(--color-orange-lighter);
}

.textCenteredDesktop{
    text-align: center;
}


@media (max-width: 1240px) {
    .blockTitle{
        font-size: 36px;
        line-height: normal;
    }
}

@media (max-width: 768px) {
    .blockTitle{
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .textCenteredDesktop{
        text-align: initial;
    }
}