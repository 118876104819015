.footer{
    margin-top: 70px;
    padding-top: 90px;
    border-top: 1px solid #f1f1f1;
}

.mainContent{
    display: flex;
    justify-content: space-between;
}

.mainContent > *:first-child{
    margin-left: 0;
}

.copyright{
    margin-top: 110px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    text-align: center;
    color: #cccccc;
    margin-bottom: 30px;
}

@media (min-width: 769px) {
    .hiddenDesktop{
        display: none;
    }
}

@media (max-width: 768px) {
    .footer{
        margin-top: 15px;
        padding-top: 60px;
    }
    .mainContent{
        padding: 0 30px;
        display: block;
    }
    .mainContent button{
        margin: 0 0 40px 0;
    }
    .mobileHidden{
        display: none;
    }
    .copyright{
        margin: 40px 30px;
        text-align: left;
    }
}