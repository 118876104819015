.svg{
    width: 80px;
    height: 29px;
}
.logo{
    transition: .3s all ease;
    fill: var(--color-blue);
}

@media (max-width: 768px) {
    .svg{
        width: 66px;
        height: 24px;
    }
}