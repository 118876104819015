.container {
    padding: 50px 15px 50px;
    background-color: var(--color-light-grey-bg);
}

.list {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 0 40px;
}

.listItem {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--color-blue-text);
    background: #fff;
    border-radius: 14px;
    box-shadow: 0px 8px 19px rgba(157, 179, 194, 0.24);
    overflow: hidden;
}

.listItemThumbnail {
    display: block;
    height: 230px;
}

.listItemThumbnail img {
    display: block;
    width: 100%;
    height: 100%;
}

.listItemBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: auto;
    padding: 22px 40px;
}

.listItemTitle {
    display: inline-block;
    color: inherit;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
}

.listItemLink {
    position: relative;
    color: var(--color-orange-lighter);
    font-size: 14px;
    font-weight: 700;
    padding-right: 22px;
}

.listItemLink::before {
    content: '';
    position: absolute;
    top: 7px;
    right: 0;
    width: 12px;
    height: 6px;
    background: url('/icons/arrow.svg') no-repeat;
    transition: right .2s ease-in-out;
}

.listItemLink:hover::before {
    right: -3px;
}
