.container {
    padding: 50px 15px 50px
}

.theftSection {
    display: flex;
    margin-left: -18px;
    margin-right: -18px;
    flex-wrap: wrap;
    justify-content: center;
}

.theftSectionItem {
    flex: 0 0 50%;
    display: block;
    padding: 0 18px;
    margin-bottom: 25px;
}

.theftSectionItemTitle {
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    color: var(--color-blue-text);
    margin-bottom: 35px;
}

.theftSectionItemGif {
    border-radius: 14px;
    overflow: hidden;
}

.theftSectionItemGif video {
    display: block;
    width: 100%;
}

.desc p {
    text-align: center;
}

@media (max-width: 768px) {
    .theftSection {
        display: block;
    }
}