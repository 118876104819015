.formInput {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--color-grey-input-border);
    border-radius: 3px;
    padding: 18px;
    font-size: 16px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.formInput:focus {
    outline: none;
    border-color: var(--color-orange-lighter);
}

.formInput.invalidValue{
    border-color: var(--color-input-error-border);
}
.formInput.invalidValue:focus{
    border-color: var(--color-orange-lighter);
}