.socialLinkItem{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin: 0 10px;
    transition: .3s all ease;
    border: 1px solid #F1F1F1;
}
