.button{
    height: fit-content;
    margin: 12px;
    padding: 20px 30px;
    color: white;
    background: grey;
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: .2em;
    text-transform: uppercase;
    border: none;
    border-radius: 6px;
    transition: .3s all ease;
    cursor: pointer;
}
.button.primary{
    color: white;
    background: var(--color-orange-lighter);
}

.button.secondary{
    color: black;
    background: transparent;
    border: 1px solid var(--color-orange-lighter);
}

.button.primary:hover, .button.secondary:hover{
    background: var(--color-orange-saturated);
    color: white;
}
.button.primary:focus, .button.secondary:focus{
    outline: none;
}
.button.primary:active, .button.secondary:active{
    transform: scale(.95);
}

@media (max-width: 568px) {
    .button {
        width: 100%;
        display: block;
        margin-left: 0;
        margin-right: 0;
    }
}