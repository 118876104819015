.cloud{
    animation: cloudIntro 1s 1 var(--intro-animation-curve-ifs);
}
.server{
    animation: serverIntro 1s 1 var(--intro-animation-curve-ifs);
}
.camera{
    animation: cameraIntro 1s 1 var(--intro-animation-curve-ifs);
}
.backgroundCircle{
    fill: #f8f8f8;
    animation: backgroundCircleIntro 1s 1 ease-out .5s backwards;
}

@keyframes cloudIntro{
    0%{
        transform: translateY(50px);
    }
    100%{
        transform: initial;
    }
}

@keyframes serverIntro{
    0%{
        transform: translateX(-30px);
    }
    100%{
        transform: initial;
    }
}

@keyframes cameraIntro{
    0%{
        transform: translateX(30px);
    }
    100%{
        transform: initial;
    }
}

@keyframes backgroundCircleIntro{
    0%{
        opacity: 0;

    }
    100%{
        opacity: 1;
    }
}